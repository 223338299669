import styles from './NotFoundPage.module.css'; 
import NotFoundImage from './../images/404.jpeg';

const NotFoundPage = () => {
  return (
    <div className={styles['not-found-container']}>
      <img src={NotFoundImage} alt="404" className={styles['not-found-image']} />
    </div>
  );
};

export default NotFoundPage;
