import './App.css';
import HomePage from './pages/HomePage/HomePage';
import TestPage from './pages/TestPage/TestPage';
import ResultPage from './pages/ResultPage/ResultPage';
import ImagePage from './pages/ImagePage/ImagePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/results" element={<ResultPage />} />
        <Route path="/image" element={<ImagePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>

  );
}

export default App;
